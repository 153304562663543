/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-04-01",
    versionChannel: "nightly",
    buildDate: "2025-04-01T00:07:25.318Z",
    commitHash: "6954bb863c7cc2c53dfdf6b4fe05eec355d400e4",
};
